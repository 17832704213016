// user_mass_actions_controller.js
import ApplicationController from "./application_controller";

export default class SecureFieldEditController extends ApplicationController {
  static targets = [
    'displayField',
    'editField',
    'editFieldContainer',  // Field and its container can be same too
    'editBtn',
    'toggleFieldValueVisibility',
    'toggleFieldType'
  ]
  
  initialize(){
  }

  // Not required at the moment
  toggleSecureFieldValueVisibility(){
    if (this.editFieldTarget.type === 'password'){
      this.editFieldTarget.type = 'text';

      this.toggleFieldValueVisibilityTarget?.classList?.toggle('fa-eye', true);
      this.toggleFieldValueVisibilityTarget?.classList?.toggle('fa-eye-slash', false);  
    } else {
      this.editFieldTarget.type = 'password';

      this.toggleFieldValueVisibilityTarget?.classList?.toggle('fa-eye-slash', true);
      this.toggleFieldValueVisibilityTarget?.classList?.toggle('fa-eye', false);
    }
  }

  displayViewOnlyField(){
    // Updating value in the displayField - START
    let newValue         = this.editFieldTarget.value;
    if (!newValue){
      return;
    }

    let newValueLastFive = newValue.slice(-5); // Get last 5 characters
    this.displayFieldTarget.value = "********************" + newValueLastFive;
    // Updating value in the displayField - END


    this.editFieldContainerTarget.classList.toggle('collapse', true)
    if (this.hasToggleFieldType){
      this.toggleFieldTypeTarget.classList.toggle('collapse', true)
    }

    this.editBtnTarget.classList.toggle('collapse', false)
    this.displayFieldTarget.classList.toggle('collapse', false)
  }

  displayEditOnlyField(){
    // Resetting API Key Field
    this.editFieldTarget.value = '';

    this.editFieldContainerTarget.classList.toggle('collapse', false)
    if (this.hasToggleFieldType){
      this.toggleFieldTypeTarget.classList.toggle('collapse', false)
    }

    this.editBtnTarget.classList.toggle('collapse', true)
    this.displayFieldTarget.classList.toggle('collapse', true)
  }
}
