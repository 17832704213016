// user_mass_actions_controller.js
import ApplicationController from "./application_controller";

export default class UsersMassActionsController extends ApplicationController {
  static targets = [
    'checkbox',
    'selectAllCheckbox'
  ]
  
  initialize(){
  }

  markAll(){
    let checkedState = this.selectAllCheckboxTarget.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checkedState;
    })
  }

  checkedCheckboxes(){
    return this.checkboxTargets.filter((checkbox) => checkbox.checked); 
  }

  toggleMassActionField(e){
    let referenceFieldId = e.currentTarget.dataset.referenceFieldId
    let referenceField = document.querySelector('#' + referenceFieldId)
    if (e.currentTarget.checked) {
      referenceField.disabled = false
    } else {
      referenceField.disabled = true
    }
  }

  submitBulkUpdate(e){
    e.preventDefault();

    let selectedUserIds = [];
    let selectedAttributesFields = document.querySelectorAll('.js-enable-field-mass-update:checked')
    
    // selected input(checkbox) from mass action modal
    if (selectedAttributesFields.length == 0) {
      alertCustom('No attribute is selected to update.', { alertType: 'error', displayPlacement: 'hover' })
      return
    }

    // selected users from users for bulk action
    const selectedUsersCheckboxes = this.checkedCheckboxes();
    if (selectedUsersCheckboxes.length > 0) {
      selectedUserIds = selectedUsersCheckboxes.map(input => input.dataset.userId);
    } else {
      alertCustom('No User is selected for the specified Action', { alertType: 'error', displayPlacement: 'hover' })
      return
    }

    var formData =  $('#mass-action-form').serializeArray();
    selectedUserIds.forEach(user_id => {
      formData.push({ name: 'user_ids[]', value: user_id });
    });
    
    $.ajax({
      url: Routes.bulk_update_users_path(),
      type: 'PATCH',
      data: $.param(formData),
    });
  }
}
